.pool-extend-error {
  color: $attention-alarm;
  border: 1px solid $attention-alarm-border;
  border-radius: 16px;
  font-size: 14px;
  background: $attention-alarm-background;
  padding: 16px;
  margin-top: 16px;
  font-weight: 500;
  a {
    font-weight: bold;
    color: $attention-alarm;
  }
}

.pool-extend-info {
  margin-top: 40px;
  text-align: center;
  font-weight: bold;
  .copy-icon {
    vertical-align: middle;
    cursor: pointer;
  }
}

.pool-extend-extra-information {
  font-size: 14px;
  color: $ui-contrast;
  border: 1px solid $attention-warning;
  background: $attention-warning-background;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.pool-extend {
  > *:not(:last-child) {
    margin-right: 20px;
  }
}
