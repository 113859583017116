.pool-content-header {
  margin-bottom: 30px;
}

.pool-content-row {
  > *:not(:last-child) {
    margin-right: 20px;
  }

  button {
    min-width: 180px;
  }
}
